(function ($) {
  siteLogoResponsiveV1 = {
    formatter: $('.site-logo')
  };
  Drupal.behaviors.siteLogoResponsiveV1 = {
    get: function (key) {
      return siteLogoResponsiveV1[key];
    },

    set: function (key, val) {
      siteLogoResponsiveV1[key] = val;
    },

    attach: function (context, settings) {
      var self = this;
      var page_title = document.title;
      var formatter = self.get('formatter');

      formatter.each(function () {
        var thisFormatter = $(this);
      });
    }
  };
})(jQuery);
